import { useMemo, useState } from 'react';
import { Redirect, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tooltip } from '@redislabsdev/redislabs-ui-components';
import { theme } from '@redislabsdev/redis-ui-styles';
import { DeleteIcon } from '@redislabsdev/redis-ui-icons';
import {
  ConfirmationDialog
} from '@redislabsdev/redislabs-ui-components/ui/components/ConfirmationDialog';
import * as S from '../ContractPage.style';
import * as CS from '../../../../styles/common.style';
import TableStatus from '../../../../components/TableStatus/TableStatus';
import { getOneContract, saveContractFun } from '../../contractsPage.utils';
import { checkForProSubscriptions, deleteContract, denyBasedOnStatus } from '../ContractPage.utils';
import { CONTRACTS_WRITE } from '../../../../constants/permissionsConstants';
import { StoreInterface } from '../../../../interfaces/storeInterfaces';
import { TooltipContractWrapper } from '../../../../styles/commonAccountDetails.style';

const TitleSection = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const newContract = useRouteMatch('/contracts/new') || false;
  const view = useRouteMatch('/contracts/:id/view') || false;
  const edit = useRouteMatch('/contracts/:id/edit') || false;
  const interim = useRouteMatch('/contracts/:id/interim') || false;
  const renewal = useRouteMatch('/contracts/:id/renewal') || false;

  const { id: contractId } = useParams<{ id: string }>();

  const contractInfo = useSelector((state: StoreInterface) => state.contractsPage.contractInfo);
  const contractInfoPickedAccounts = useSelector(
    (state: StoreInterface) => state.contractsPage.contractInfoPickedAccounts
  );
  const cloudContractStatuses = useSelector(
    (state: StoreInterface) => state.contractsPage.accountsConfiguration.cloudContractStatuses
  );
  const activeProSubsAccountIds = useSelector(
    (state: StoreInterface) => state.contractPage.activeProSubsAccountIds
  );
  const touched = useSelector((state: StoreInterface) => state.contractsPage.touched);
  const contractInfoValidation = useSelector(
    (state: StoreInterface) => state.contractsPage.contractInfoValidation
  );
  const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);

  const checkMandatoryFields = () => {
    const {
      contactName,
      customerEmail,
      customerName,
      endDate,
      credit,
      discount,
      startDate,
      accWithDetails,
      redislabsEmailList,
    } = contractInfo;

    const arrayOfPaymentMethods = Array.from(accWithDetails.values());
    const paymentsInfo = arrayOfPaymentMethods.map((item) => item?.paymentInfos?.length);

    return (
      contactName &&
      customerEmail &&
      customerName &&
      endDate &&
      String(credit) !== '' &&
      String(discount) !== '' &&
      redislabsEmailList &&
      startDate &&
      accWithDetails.size &&
      paymentsInfo.every((item) => item > 0)
    );
  };

  const checkValidity = () => {
    const { customerEmail, credit, redislabsEmailList, discount } = contractInfoValidation;

    return customerEmail && credit && redislabsEmailList && discount;
  };

  const ableToSave = checkMandatoryFields() && checkValidity() && touched;

  const redirectToContractsPage = (newContractId) => {
    dispatch({ type: 'resetContractInfo' });

    if (newContractId) {
      return history.push(`/contracts/${newContractId}/view`);
    }

    if (view) {
      return history.push('/contracts');
    }

    return history.goBack();
  };

  const saveContractBtn = useMemo(() => {
    return (
      <Button
        data-testid="button--save-contract"
        onClick={() =>
          saveContractFun({
            reqParams: {
              contractInfo,
              contractInfoPickedAccounts,
            },
            interim: interim,
            renewal : renewal,
            contractId,
            callBack: redirectToContractsPage,
          })
        }
        disabled={!ableToSave}
      >
        {`${( (interim || renewal) && 'Renew Contract') || 'Save Contract'}`}
      </Button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ableToSave, contractInfo, contractInfoPickedAccounts]);

  const renderCancelButton = () => (
    <Button
      variant="ghost"
      data-testid="button--cancel"
      onClick={() => redirectToContractsPage(false)}
    >
      Cancel
    </Button>
  );

  const renderSaveButton = () => {
    if (ableToSave) return <>{saveContractBtn}</>;

    return (
      <TooltipContractWrapper>
        <Tooltip
          tooltipContent={
            <CS.TooltipTextWithTooltip>
              Please fill in all the required (*) fields and add at least one valid payment method
            </CS.TooltipTextWithTooltip>
          }
          placement="left"
          trigger="hover"
          textColor={theme.semantic.color.text.neutral700}
        >
          {saveContractBtn}
        </Tooltip>
      </TooltipContractWrapper>
    );
  };

  const renderDeleteButton = () => {
    const { status, renewalContractId } = contractInfo;
    const canWriteContracts = permissions.includes(CONTRACTS_WRITE);
    const denyDeleteBasedOnStatus = denyBasedOnStatus(status); // used to remove the button on
    // various statuses

    if (!canWriteContracts || denyDeleteBasedOnStatus || renewalContractId) return null;

    return (
      <Button
        size="small"
        data-testid="button--delete-contract"
        onClick={async () => {
          await checkForProSubscriptions(contractInfo.accounts, dispatch);

          setShowDeleteModal(true);
        }}
      >
        <DeleteIcon size="L" />
        Delete Contract
      </Button>
    );
  };

  const renderEditButton = () => {
    const { status } = contractInfo;
    const canWriteContracts = permissions.includes(CONTRACTS_WRITE);
    const denyEditBasedOnStatus = denyBasedOnStatus(status); // used to remove the button on
    // various statuses

    if (!canWriteContracts || denyEditBasedOnStatus) return null;

    return (
      <Button
        data-testid="button--edit-contract"
        onClick={() => history.push(`/contracts/${contractId}/edit`)}
      >
        Edit Contract
      </Button>
    );
  };

  const buildContractLink = (contractId: string) => {
    return `/contracts/${contractId}/view`;
  }

  const renderContractLink = (linkTextPrefix: string, contractId: string) => {
    return (
      <span>(
        <S.Link href={buildContractLink(contractId)}>
          {`${linkTextPrefix} Contract ID ${contractId}`}</S.Link>
        )
      </span>
    )
  }

  const renderButtons = () => {
    const { status, expandedContractId, renewalContractId } = contractInfo;

    if (newContract) {
      return (
        <>
          <CS.JustifyToLeft>
            <CS.PageHeaderTitle>Create New Contract</CS.PageHeaderTitle>
          </CS.JustifyToLeft>
          <CS.JustifyToRight>
            {renderCancelButton()}
            {renderSaveButton()}
          </CS.JustifyToRight>
        </>
      );
    }

    return (
      <>
        <CS.JustifyToLeft>
          <div>
            <CS.PageHeaderTitle>{`Contract ID ${contractId}`}</CS.PageHeaderTitle>
            {expandedContractId && (
              renderContractLink("Renewal of", expandedContractId)
            )}
          </div>
          <S.DeleteStatusWrapper>
            {renderDeleteButton()}
            <TableStatus
              status={status}
              statuses={cloudContractStatuses}
              dataTestId="contract-status"
            />
          </S.DeleteStatusWrapper>
          {renewalContractId && (
            renderContractLink("New", renewalContractId)
          )}
        </CS.JustifyToLeft>
        <CS.JustifyToRight>
          {renderCancelButton()}
          {view ? renderEditButton() : renderSaveButton()}
        </CS.JustifyToRight>
      </>
    );
  };

  const buildDeletePopupMessage = () => {
    const message = ' you are about to delete this contract';
    const completedMessage = (
      <div>{'but the following account(s) have active subscriptions: '}</div>
    );
    const ids = (
      <S.BoldDiv>{`${activeProSubsAccountIds.toString().replace(/,/g, ', ')}`}</S.BoldDiv>
    );

    return (
      <S.DeleteWarning>
        <S.ColorWarning>Warning:</S.ColorWarning>
        <span>
          {message}
          {`${activeProSubsAccountIds.length ? ', ' : '!'}`}
        </span>
        {activeProSubsAccountIds.length ? (
          <>
            {completedMessage}
            {ids}
          </>
        ) : null}
      </S.DeleteWarning>
    );
  };

  if ((interim || edit || renewal) && denyBasedOnStatus(contractInfo.status)) {
    // redirects if the contract doesn't have the correct status for expand and edit and renewal
    return <Redirect to="/home" />;
  }
  
  if ((interim || renewal)  && contractInfo.renewalContractId) {
    redirectToContractsPage(contractInfo.renewalContractId);
  }

  return (
    <CS.PageTitleAndActions>
      {renderButtons()}
      <ConfirmationDialog
        title="Are you sure?"
        isOpen={showDeleteModal}
        cancelButtonLabel="Cancel"
        submitButtonLabel="Delete Contract"
        handleModalCancel={() => setShowDeleteModal(false)}
        handleModalSubmit={() => {
          deleteContract(
            contractId,
            () => {
              getOneContract(contractId, dispatch);
            }
          ).then(() => {
            setShowDeleteModal(false);
          });
        }}
        shouldCloseOnOverlayClick
      >
        {buildDeletePopupMessage()}
      </ConfirmationDialog>
    </CS.PageTitleAndActions>
  );
};

export default TitleSection;
